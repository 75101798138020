import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as EmailIcon } from "../assets/email.svg";
import zxcvbn from "zxcvbn";

const Signup = () => {
  const [err, setErr] = useState([]);
  const { signup, verifyUser } = useAuth();
  const [emailSent, setEmailSent] = useState(false);
  const emailRef = useRef();
  const passRef = useRef();
  const confirmPassRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setErr([]);
      const userCredential = await signup(
        emailRef.current.value,
        passRef.current.value
      );
      await verifyUser(userCredential.user);
      setEmailSent(true);
    } catch (error) {
      setErr(["Failed to create an account"]);
    }
  };

  const validateForm = () => {
    const scoreRef = zxcvbn(passRef.current.value);
    const emailAddress = emailRef.current.value;
    const passwd = passRef.current.value;
    if (emailAddress && emailAddress.indexOf("@vidiumah.com") === -1) {
      setErr(["Only Vidium Users Allowed to Signup"]);
      return false;
    } else if (passwd !== confirmPassRef.current.value) {
      setErr(["Passwords do not match"]);
      return false;
    }
    if (passwd && scoreRef.score <= 2) {
      setErr([scoreRef.feedback.warning, scoreRef.feedback.suggestions]);
      return false;
    }
    setErr([]);
    return true;
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 form-container">
      <div className="auth-form">
        <h1 className="h2">
          <Link to={"/"}>Knowledgebase</Link>
        </h1>
        <h2 className="my-3 h3">Signup</h2>
        {emailSent ? (
          <div className="text-center">
            <EmailIcon height="95" />
            <p className="mt-4">
              A verification email has been sent to {emailRef.current.value}.
              Please check your inbox and verify the email.
            </p>
          </div>
        ) : (
          <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                ref={emailRef}
                className="form-control"
                type="email"
                name="email"
                id="email"
                placeholder="Enter your Vidium Email Address"
                onChange={() => validateForm()}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                ref={passRef}
                className="form-control"
                type="password"
                name="password"
                id="password"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Confirm Password</label>
              <input
                ref={confirmPassRef}
                className="form-control"
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                required
                onChange={() => validateForm()}
              />
              {err.length > 0 && (
                <small className="form-text text-danger mt-3">
                  {err.map((currErr) => (
                    <p style={{ marginBottom: 0 }}>*{currErr}</p>
                  ))}
                </small>
              )}
            </div>
            <button
              type="submit"
              className="btn-custom mt-1"
              onClick={(e) => handleSubmit(e)}
            >
              Signup
            </button>
            <p>
              Already have an account? Please{" "}
              <Link to={"/login"}>click here</Link> to Login
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default Signup;
