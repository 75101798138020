import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as EmailIcon } from "../assets/email.svg";

const ForgotPassword = () => {
  const [err, setErr] = useState();
  const [msg, setMsg] = useState();
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErr("");
      const emailAddress = emailRef.current.value;
      await resetPassword(emailAddress);
      setMsg(
        `Please check your inbox at ${emailAddress} for password reset instructions`
      );
    } catch (error) {
      setErr("Failed to Reset Password");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 form-container">
      <div className="auth-form">
        <h1>
          <Link to={"/"}>Knowledgebase</Link>
        </h1>
        <h2 className="h3 p-4">Forgot Password</h2>
        {msg ? (
          <div className="text-center text-dark fs-5">
            <EmailIcon width="125" height="125" />
            <p className="mw-50">{msg}</p>
          </div>
        ) : (
          <form
            className="d-flex flex-column gap-4 pt-"
            onSubmit={(e) => handleSubmit(e)}
          >
            {err && <p className="alert alert-danger">{err}</p>}
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                ref={emailRef}
                className="form-control"
                type="email"
                name="email"
                id="email"
              />
            </div>
            <button type="submit" className="btn-custom mt-2">
              Submit
            </button>
          </form>
        )}
        <div className="text-center">
          <br />
          <small>
            <Link to={"/login"}>Return to login page</Link>{" "}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
