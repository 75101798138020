import React from "react";

const Error = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <h1 className="text-danger display-2">Error</h1>
      <h2>404! Page Not Found</h2>
    </div>
  );
};

export default Error;
