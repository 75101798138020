import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import { DataProvider } from "./contexts/DataContext";
import Home from "./layouts/Home";
import Alteration from "./pages/Alteration";
import Disease from "./pages/Disease";
import Error from "./components/Error";
import Drugs from "./pages/Drug";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AuthProvider from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  const API_URL = process.env.REACT_APP_BASE_API_URL;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<Error />}>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="resetpassword" element={<ForgotPassword />} />
        <Route
          path="/"
          exact
          element={
            <PrivateRoute>
              <Home endpoint={API_URL} />
            </PrivateRoute>
          }
        >
          <Route index element={<Alteration />} />
          <Route path="associations" element={<Disease />} />
          <Route path="drugs" element={<Drugs />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Route>
    )
  );
  return (
    <div className="App">
      <DataProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </DataProvider>
    </div>
  );
}

export default App;
