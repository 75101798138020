import React, { useEffect } from "react";

import DataTable from "datatables.net-bs5";

const Table = ({ headerLabels, data }) => {
  const formatData = (data) => {
    if (!data) return <span>-</span>;
    if (data.startsWith("http"))
      return (
        <a target="_blank" rel="noreferrer" href={data}>
          {data}
        </a>
      );
    return data;
  };

  useEffect(() => {
    new DataTable("#results-table");
  }, []);

  return (
    <div className="table-container">
      <table
        className="table table-bordered table-striped table-hover dataTable"
        id="results-table"
      >
        <thead className="table-dark">
          <tr>
            <th></th>
            {headerLabels.map((label, idx) => (
              <th key={idx}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((obj, idx) => (
            <tr key={idx}>
              <td className="text-muted">{idx + 1}</td>
              {Object.values(obj).map((currObj, objIdx) => (
                <td key={objIdx}>{formatData(currObj)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
