import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, useOutletContext } from "react-router-dom";
import Table from "../components/Table";
import Popup from "../components/Popup";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";

const Disease = () => {
  const { endpoint } = useOutletContext();
  const { currentUser } = useAuth();
  const { obj, setObj } = useData();
  const disease = obj.diseases;
  const [input, setInput] = useState({ disease: "" });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [uniqueGenes, setUniqueGenes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    var genes = disease.map((e) => e.gene_name);
    var unique = genes.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    setUniqueGenes(unique);
  }, [disease]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!input.disease) return;
      setLoading(true);
      const token = await currentUser.getIdToken();
      const headers = {
        authorization: `Bearer ${token}`,
      };
      const { data } = await axios.get(
        `${endpoint}/api/associations?disease=${input.disease}`,
        { headers: headers }
      );
      if (data.length === 0) {
        setErr("Please submit a valid input and try again!");
      } else {
        setErr("");
      }
      setObj({ ...obj, diseases: data });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="results">
      {showPopup && (
        <Popup
          data={uniqueGenes}
          heading={`List of Unique Genes`}
          setShowPopup={setShowPopup}
        />
      )}
      <Form className="queryForm" onSubmit={handleSubmit}>
        <input
          type="text"
          name="disease"
          id="disease"
          placeholder="Enter disease name here"
          onChange={(e) => handleChange(e)}
        />
        <button className="btn-custom" onClick={handleSubmit}>
          Submit
        </button>
      </Form>
      <h1 className="section-heading">Results</h1>
      {disease.length > 0 ? (
        loading ? (
          "Loading..."
        ) : (
          <div>
            <button
              className="btn-custom my-2"
              onClick={() => setShowPopup(true)}
            >
              Show Unique Genes
            </button>
            <button
              className="btn-custom scroll-btn"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Top ↑
            </button>
            <Table
              headerLabels={[
                "Cancer Type (Vidium)",
                "Gene Name",
                "Mutation (pdot)",
              ]}
              data={disease}
            />
          </div>
        )
      ) : err ? (
        <p className="text-danger fw-bold mt-1">{err}</p>
      ) : (
        <p className="text-muted">Please submit a query to get results.</p>
      )}
    </div>
  );
};

export default Disease;
