import React, { createContext, useContext, useState } from "react";

export const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [obj, setObj] = useState({ alterations: [], diseases: [], drugs: [] });

  return (
    <DataContext.Provider value={{ obj, setObj }}>
      {children}
    </DataContext.Provider>
  );
};
