import React, { useEffect, useRef, useState } from "react";
// import { useData } from "../contexts/DataContext";
import { ReactComponent as ClipboardIcon } from "../assets/clipboard.svg";

const Popup = ({ data, heading, setShowPopup }) => {
  const [copied, setCopied] = useState(false);
  const popupRef = useRef(null);
  const closePopup = () => {
    setShowPopup(false);
  };

  async function copyToClipboard(dataValues) {
    try {
      if (!("clipboard" in navigator)) {
        console.log("Copy not supported!");
        return;
      }
      const dataToCopy = dataValues.join('\n');
      await navigator.clipboard.writeText(dataToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      /* Rejected - text failed to copy to the clipboard */
    }
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupRef && !popupRef.current.contains(e.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [setShowPopup]);

  return (
    <div className="popup-container">
      <div id="overlay"></div>
      <div className="popup" ref={popupRef}>
        <button
          className="btn-close close float-end"
          onClick={() => closePopup()}
        ></button>
        <h1 className="h3 d-flex align-items-center">
          {heading}
          <button
            onClick={() => copyToClipboard(data)}
            className="btn-clipboard"
          >
            {
              <ClipboardIcon
                width="25"
                height="25"
                className="ms-2 mb-1 clipboard-icon"
              />
            }
          </button>
          {copied && <span className="copy-text">Copied ✓</span>}
        </h1>
        <table className="table table-bordered table-striped table-hover mt-4">
          <thead className="table-dark">
            <tr>
              <th>Gene Name</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((entry, idx) => (
                <tr key={idx}>
                  <td>{entry}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Popup;
