import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useAuth } from "../contexts/AuthContext";

const Home = ({ endpoint }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Sidebar endpoint={endpoint} />
      <main>
        <div className="body-text">
          <div id="top"></div>
          <div className="top-nav d-flex flex-column align-items-end">
            <button className="btn btn-danger" onClick={(e) => handleClick(e)}>
              Logout
            </button>
            <p className="text-muted text-end">
              You are logged in as <br />{" "}
              <span className="text-primary">{currentUser.email}</span>
            </p>
          </div>
          <Outlet context={{ endpoint }} />
        </div>
      </main>
    </>
  );
};

export default Home;
