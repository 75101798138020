import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const URL_PARAMS = new URLSearchParams(window.location.search);

const Login = () => {
  const [err, setErr] = useState();
  const [msg, setMsg] = useState();
  const emailAdd = URL_PARAMS.get("email");
  const emailRef = useRef();
  const passRef = useRef();
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErr("");
      await login(emailRef.current.value, passRef.current.value);
      setMsg("Logging you right in...");
      if (currentUser && !currentUser.emailVerified)
        return setErr("Please verify your email address and try again.");
      setTimeout(() => navigate("/"), 500);
      setMsg("");
    } catch (error) {
      setErr("Failed to Login");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 form-container">
      <div className="auth-form">
        <h1>
          <Link to={"/"}>Knowledgebase</Link>
        </h1>
        {msg ? (
          msg
        ) : (
          <form
            className="d-flex flex-column gap-4 mt-5"
            onSubmit={(e) => handleSubmit(e)}
          >
            {err && <p className="alert alert-danger">{err}</p>}
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                ref={emailRef}
                className="form-control"
                type="email"
                name="email"
                id="email"
                defaultValue={emailAdd}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                ref={passRef}
                className="form-control"
                type="password"
                name="password"
                id="password"
              />
            </div>
            <button type="submit" className="btn-custom mt-2">
              Submit
            </button>
            <div className="text-center">
              <Link className="btn btn-login" to={"/signup"}>
                Create new account
              </Link>
              <br />
              <small>
                <Link to={"/resetpassword"}>Forgot password?</Link>{" "}
              </small>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
