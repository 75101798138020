import axios from "axios";
import React, { useState } from "react";
import { Form, useOutletContext } from "react-router-dom";
import Table from "../components/Table";
import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";

const Drugs = () => {
  const { endpoint } = useOutletContext();
  const [input, setInput] = useState({ gene: "", mutation: "" });
  const { currentUser } = useAuth();
  const { obj, setObj } = useData();
  const drugs = obj.drugs;
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = await currentUser.getIdToken();
      const headers = {
        authorization: `Bearer ${token}`,
      };
      const { data } = await axios.get(
        `${endpoint}/api/drugs?gene=${input.gene}&pdot=${input.mutation}`,
        { headers: headers }
      );
      if (data.length === 0) {
        setErr("Please submit a valid input and try again!");
      } else {
        setErr("");
      }
      setObj({ ...obj, drugs: data });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <div className="results">
      <Form className="queryForm" onSubmit={handleSubmit}>
        <input
          type="text"
          name="gene"
          id="gene"
          placeholder="Enter gene name here"
          onChange={(e) => handleChange(e)}
        />
        <input
          type="text"
          name="mutation"
          id="mutation"
          placeholder="Enter mutation name here"
          onChange={(e) => handleChange(e)}
        />
        <button className="btn-custom" onClick={handleSubmit}>
          Submit
        </button>
      </Form>
      <h1 className="section-heading">Results</h1>
      {drugs.length > 0 ? (
        loading ? (
          "Loading..."
        ) : (
          <div>
            <button
              className="btn-custom scroll-btn"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Top ↑
            </button>
            <Table
              headerLabels={[
                "Gene Name",
                "Mutation (pdot)",
                "Cancer Type (Vidium)",
                "Cancer Type (Journal)",
                "Drug Name",
                "Evidence Level",
                "Species",
                "Source URL",
              ]}
              data={drugs}
            />
          </div>
        )
      ) : err ? (
        <p className="text-danger fw-bold mt-1">{err}</p>
      ) : (
        <p className="text-muted">Please submit a query to get results.</p>
      )}
    </div>
  );
};

export default Drugs;
