import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ endpoint }) => {
  const [version, setVersion] = useState();
  useEffect(() => {
    const getVersion = async () => {
      const { data } = await axios.get(`${endpoint}/api/version`);
      setVersion(data[0].kb_version_name);
    };
    getVersion();
  }, [endpoint]);
  return (
    <div className="sidebar">
      <h1 className="logo">Knowledgebase</h1>
      <nav>
        <ul className="nav-container">
          <li>
            <NavLink className="sidebar-nav" to="/">
              Gene Alteration
            </NavLink>
          </li>
          <li>
            <NavLink className="sidebar-nav" to="/associations">
              Disease Association
            </NavLink>
          </li>
          <li>
            <NavLink className="sidebar-nav" to="/drugs">
              Drug Association
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="footer">
        <p className="mb-2">(Powered by KB {version})</p>
        <p>© {new Date().getFullYear()} Vidium Animal Health</p>
      </div>
    </div>
  );
};

export default Sidebar;
